<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <!-- <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-for="initial-sort-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="initial-sort-select"
            v-model="sortDirection"
            :options="['asc', 'desc', 'last']"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="name">Name</b-form-checkbox>
            <b-form-checkbox value="age">Age</b-form-checkbox>
            <b-form-checkbox value="isActive">Active</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row> -->

    <!-- Main table element -->
    <b-table
      :items="getAllVehicles"
      :fields="fields"
      stacked="md"
      show-empty
      small
      sort-by="created"
      :sort-desc="true"
    >

      <template #[`cell(active)`]="row">
        <b-badge
          v-if="row.item.active"
          pill
          variant="success">Live</b-badge>
        <b-badge
          v-else
          pill
          variant="danger">Inactive</b-badge>
        <b-badge
          v-if="row.item.featured"
          pill
          variant="info"
          class="ml-1">Featured</b-badge>
      </template>

      <template #[`cell(created)`]="row">
        {{ formatDateTime(row.value) }}
      </template>

      <template #[`cell(vehicle.registrationDate)`]="row">
        {{ formatYear(row.value) }}
      </template>

      <template #[`cell(vehicle.model)`]="row">
        {{ row.value }} {{ row.item.vehicle.variant }}
      </template>

      <template #[`cell(advert.price)`]="row">
        £{{ row.value }}
      </template>

      <template #cell(actions)="row">
        <edit-stock 
          v-if="row.item.createdBy === user.email" 
          :editing-vehicle-reg="row.item.vehicle.reg" />
        <edit-stock
          v-else-if="user.email === 'sales@midlandautosales.co.uk'" 
          :editing-vehicle-reg="row.item.vehicle.reg" />
        <div style="display: inline-block; width: 30px;">
          <i @click="showPhotosModal(row.item, row.index, $event.target)" style="cursor: pointer;" class="fa fa-camera"></i>
        </div>
        <div style="display: inline-block; width: 30px;">
          <i @click="row.toggleDetails" style="cursor: pointer;" :class="row.detailsShowing ? 'fa fa-minus-circle' : 'fa fa-info'"></i>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row>
            <b-col cols="4">
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Make</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.make"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Model</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.model"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Variant</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.variant"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Body Type</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.bodyType"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Colour</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.colour"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Seats</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.seats"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Engine Size</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.engine"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Fuel Type</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.fuelType"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Power (BHP)</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.power"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Transmission</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.transmission"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Tax Band</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.taxBand"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">First Registration</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="formatDate(row.item.vehicle.registrationDate)"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right">
                  <label class="field-label">Mileage</label>
                </b-col>
                <b-col>
                  <b-form-input
                    :value="row.item.vehicle.mileage"
                    disabled
                    size="sm"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Title</label>
                  </b-col>
                  <b-col>
                    {{ row.item.advert.title }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Subtitle</label>
                  </b-col>
                  <b-col>
                    {{ row.item.advert.subtitle }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Asking Price</label>
                  </b-col>
                  <b-col>
                    £{{ row.item.advert.price }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Description</label>
                  </b-col>
                  <b-col>
                    {{ row.item.advert.description }}
                  </b-col>
                </b-row>
                <b-row class="mt-3 mb-3">
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Features</label>
                  </b-col>
                  <b-col>
                    <b-badge
                      v-for="(feature, index) in row.item.advert.features"
                      :key="index"
                      style="margin-right: 5px;"
                      pill
                      variant="primary">{{ feature.Name }}</b-badge>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Owners</label>
                  </b-col>
                  <b-col>
                    {{ row.item.vehicle.owners }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">Service History</label>
                  </b-col>
                  <b-col>
                    {{ row.item.advert.serviceHistory }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right" cols="3">
                    <label class="field-label">MOT Due</label>
                  </b-col>
                  <b-col>
                    {{ formatDate(row.item.vehicle.motDue) }}
                  </b-col>
                </b-row>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>

    <!-- Photos modal -->
    <b-modal :id="editModalId" size="xl" hide-footer @hide="resetEditModal">
      <b-row>
        <b-col>
          Edit
        </b-col>
      </b-row>
    </b-modal>

    <!-- Photos modal -->
    <b-modal :id="photosModalId" size="xl" hide-footer @hide="resetPhotosModal">
      <b-row>
        <b-col>
          <b-button v-if="photosModalVehicleCreatedBy === user.email"
            @click="uploadImageBtnClick"
            :disabled="cloudUploadProgress !== 0 && cloudUploadProgress !== 100"
            pill
            variant="outline-primary"
            size="sm">
            <template v-if="cloudUploadProgress !== 0 && cloudUploadProgress !== 100">
              <b-icon icon="upload"></b-icon> Progress: {{ cloudUploadProgress}}%
            </template>
            <template v-else>
              <b-icon icon="upload"></b-icon> Add Photo
            </template>
          </b-button>
          <b-button v-else-if="user.email === 'sales@midlandautosales.co.uk'"
            @click="uploadImageBtnClick"
            :disabled="cloudUploadProgress !== 0 && cloudUploadProgress !== 100"
            pill
            variant="outline-primary"
            size="sm">
            <template v-if="cloudUploadProgress !== 0 && cloudUploadProgress !== 100">
              <b-icon icon="upload"></b-icon> Progress: {{ cloudUploadProgress}}%
            </template>
            <template v-else>
              <b-icon icon="upload"></b-icon> Add Photo
            </template>
          </b-button>
          <input
            type="file"
            ref="fileUpload"
            style="display: none;"
            @change="uploadImage"
            accept="image/*"
            multiple />
        </b-col>
        <b-col v-if="photosModalImages.length" class="text-right">
          <b-badge variant="info"> Click &amp; drag to reorder images</b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-center">
          <draggable v-model="photosModalImages">
            <div v-for="(photo, index) in photosModalImages" class="photo-preview" style="display: inline-block; margin: 5px;" :key="index">
              <img
                @click="deletePhoto($event, photo)"
                :src="photo.url"
                :alt="photo.storageRef"
                class="photo-preview" />
              <b-button 
                v-if="photosModalVehicleCreatedBy === user.email" 
                class="btnDelete" variant="primary" @click="deletePhoto(photo)" size="sm">
                Delete
              </b-button>
              <b-button 
                v-else-if="user.email === 'sales@midlandautosales.co.uk'"  
                class="btnDelete" variant="primary" @click="deletePhoto(photo)" size="sm">
                Delete
              </b-button>
            </div>
          </draggable>
          <!-- <b-card-group columns>
            <draggable v-model="photosModalImages">
                <b-card v-for="(photo, index) in photosModalImages"
                  :img-src="photo.url"
                  :img-alt="photo.storageRef"
                  :key="index"
                  :img-width="20"
                  :img-height="200"
                  style="cursor: move"
                >
                  <b-button variant="primary" @click="deletePhoto(photo)" size="sm">Delete</b-button>
                </b-card>
            </draggable>
           </b-card-group> -->
        </b-col>
      </b-row>
    </b-modal>

  </b-container>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'ManageStock',
  components: {
    draggable,
    EditStock: () => import('../components/EditStock')
  },
  data() {
    return {
      fields: [
        { key: 'active', label: 'Status', sortable: true, class: 'text-center' },
        { key: 'created', label: 'Created', sortable: true },
        { key: 'createdBy', label: 'User', sortable: true },
        { key: 'vehicle.reg', label: 'Reg', sortable: true },
        { key: 'vehicle.make', label: 'Make', sortable: true },
        { key: 'vehicle.model', label: 'Model', sortable: true },
        { key: 'vehicle.registrationDate', label: 'Year', sortable: true },
        { key: 'advert.price', label: 'Price', sortable: true },
        { key: 'actions', label: 'Actions', class: 'text-right' }
      ],

      photosModalId: 'photos-modal',
      photosModalVehicleId: null,
      photosModalImages: [],
      photosModalVehicleCreatedBy: null,

      editModalId: 'edit-modal',
      editModalVehicleId: null,
      editModalData: {}
    }
  },
  computed: {
    ...mapState('stock', ['cloudUploadProgress']),
    ...mapState('user', ['user']),
    ...mapGetters('stock', [
      'getAllVehicles',
    ])
  },
  watch: {
    photosModalImages() {
      if (this.photosModalVehicleId) {
        this.dbUpdateVehicleImages({
          id: this.photosModalVehicleId,
          images: this.photosModalImages
        })
          .then(() => {})
          .catch(err => {
            console.error(err)
          })
      }
    }
  },
  mounted() {
    this.dbGetAllVehicles()
  },
  beforeDestroy() {
    this.dbUnsubscribeVehicles()
  },
  methods: {
    ...mapActions('stock', [
      'dbGetAllVehicles',
      'dbUnsubscribeVehicles',
      'cloudStorageUpload',
      'cloudStorageDelete',
      'dbUpdateVehicleImages',
    ]),

    formatYear(dt) {
      return moment(dt).format('YYYY')
    },
    formatDate(dt) {
      return moment(dt).format('DD/MM/YYYY')
    },
    formatDateTime(dt) {
      return moment(dt).format('DD/MM HH:mm')
    },

    showEditModal(item, index, button) {
      this.editModalData = item
      this.editModalVehicleId = item.id
      this.$root.$emit('bv::show::modal', this.editModalId, button)
    },
    resetEditModal() {
      this.editModalVehicleId = null
      this.editModalData = {}
    },

    showPhotosModal(item, index, button) {
      this.photosModalImages = item.images
      this.photosModalVehicleId = item.id
      this.photosModalVehicleCreatedBy = item.createdBy
      this.$root.$emit('bv::show::modal', this.photosModalId, button)
    },
    resetPhotosModal() {
      this.photosModalVehicleId = null
      this.photosModalImages = []
    },
    uploadImageBtnClick() {
      this.$refs.fileUpload.click()
    },
    uploadImage(event) {
      //const imageData = event.target.files[0]
      const images = event.target.files

      images.forEach(image =>{
        const firebaseStorageRef = this.photosModalVehicleId + '/' + image.name
        this.cloudStorageUpload({ image, firebaseStorageRef })
          .then(url => {
            this.photosModalImages.push({
              storageRef: firebaseStorageRef,
              url: url
            })
          })
          .catch(err => {
            console.error(err)
          })
      })

      
    },
    deletePhoto(photo){
      const pIndex = this.photosModalImages.findIndex(p => p.storageRef === photo.storageRef && p.url === photo.url)
        if (pIndex > -1) {
          this.cloudStorageDelete(photo.storageRef)
            .then(() => {
              this.photosModalImages.splice(pIndex, 1)
            })
            .catch(err => {
              console.error(err)
            })
        }
    }
  }
}
</script>

<style scoped>
.photo-preview {
  max-width: 300px;
  max-height: 300px;
  cursor: move;
}
.field-label {
  font-size: 10pt;
}

.btnDelete {
  margin-top: 5px;
}

</style>
